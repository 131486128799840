import {
  contractsColumnsAtom,
  contractsFilterParamsAtom,
  contractsQueryParamsAtom,
  contractsReloadAtom,
  contractsTableDataAtom,
  contractsViewParamsAtom,
} from './ContractTable'
import {
  createContractView,
  updateContractView,
  fetchContractViews,
  deleteContractView,
} from 'actions/contracts/contractViewActions'
import Modal from 'components/common/Modal'
import Select from 'components/common/forms/Select'
import useModal from 'hooks/useModal'
import { useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import { MDBCol, MDBRow, MDBInput, MDBBtn } from 'mdbreact'
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectObject,
  propertiesToMultiSelectOptions,
  findSelected,
} from 'utils/formUtils'

const CREATE_HEADER = 'Create Contract View'
const DELETE_HEADER = 'Delete Contract View'
const CREATE_FLAG = 'create'
const DELETE_FLAG = 'delete'

const ContractView = ({ editable, defaultView }) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    actions,
    setActions,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const [
    setContractsReload,
    setContractsViewParams,
    setContractsColumns,
    contractsFilterParams,
    contractsQueryParams,
    contractsTableData,
  ] = [
    useSetAtom(contractsReloadAtom),
    useSetAtom(contractsViewParamsAtom),
    useSetAtom(contractsColumnsAtom),
    useAtomValue(contractsFilterParamsAtom),
    useAtomValue(contractsQueryParamsAtom),
    useAtomValue(contractsTableDataAtom),
  ]

  const { contractViewsAvailable } = useSelector((state) => {
    return state.contractViews
  })

  //// LOCAL STATE.
  const [newViewName, setNewViewName] = useState('')
  const [viewOptions, setViewOptions] = useState([])
  const [dropDownSelection, setDropDownSelection] = useState(defaultView || {})

  //// MEMOS.
  const hasQueryParams = useMemo(
    () => Object.values(contractsFilterParams).flat().length === 0,
    [contractsFilterParams]
  )

  //// EFFECTS.
  useEffect(() => {
    const dropDownOptions = propertiesToMultiSelectOptions(
      selectObject(contractViewsAvailable)
    )

    if (dropDownSelection?.id || defaultView?.id) {
      const updatedDrownDownOptions = dropDownOptions.map((option) => {
        if (dropDownSelection?.id?.toString() === option.value) {
          return {
            ...option,
            checked: true,
          }
        } else if (defaultView?.id.toString() === option.value) {
          return {
            ...option,
            checked: true,
          }
        }
        return {
          ...option,
          checked: false,
        }
      })
      setViewOptions(updatedDrownDownOptions)
    } else {
      setViewOptions(dropDownOptions)
    }
    // eslint-disable-next-line
  }, [contractViewsAvailable, defaultView])

  //// FUNCTIONS.
  const toggleModal = (e, type) => {
    if (e) e.preventDefault()

    if (type === CREATE_FLAG) {
      setHeaderText(CREATE_HEADER)
      setFooter(true)
      setActions([
        {
          text: 'Create',
          color: 'primary',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
    }

    if (type === DELETE_FLAG) {
      setHeaderText(DELETE_HEADER)
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  const defaultActiveClass = () => {
    const selectedView = findSelected(viewOptions)
    if (selectedView[0].value === dropDownSelection.id) return 'active'

    return ''
  }

  //// RENDER.
  return (
    <MDBRow
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: editable ? '980px' : '2000px',
      }}
    >
      {/* SELECT-CONTRACT-VIEW */}
      <MDBCol lg="4" md="6" sm="12">
        <Select
          id="select__views"
          options={viewOptions}
          label="Select a View"
          defaultOption="Select A View"
          disabled={_.isEmpty(viewOptions)}
          search
          getValue={(e) => {
            // views without all options set, set missing options to []
            const completeSelectionParams = {}
            Object.keys(contractsFilterParams).forEach((param) => {
              if (contractViewsAvailable[e]?.selection_params[param]) {
                completeSelectionParams[param] =
                  contractViewsAvailable[e].selection_params[param]
              } else {
                completeSelectionParams[param] = []
              }
            })
            // make sure all params are set
            Object.entries(
              contractViewsAvailable[e]?.selection_params ?? {}
            ).forEach(([k, v]) => {
              if (k in completeSelectionParams === false) {
                completeSelectionParams[k] = v
              }
            })

            if (e.length) {
              // if no column set in view use columns already set on table
              const columnSelection =
                contractViewsAvailable?.[e]?.column_params ??
                contractsTableData.columns.map((col) => col.field)

              if (setContractsColumns) setContractsColumns(columnSelection)
              setDropDownSelection(contractViewsAvailable[e])
              setContractsViewParams(completeSelectionParams)
            } else {
              setDropDownSelection({})
              setContractsViewParams({})
            }
          }}
        />
      </MDBCol>

      {/* CONTRACT-VIEW-CONTROLS */}
      <MDBCol lg="8" md="6" sm="12">
        {editable ? (
          <>
            {/* UPDATE-VIEW */}
            <MDBBtn
              disabled={_.isEmpty(dropDownSelection)}
              size="sm"
              color="secondary"
              onClick={async (e) => {
                e.preventDefault()

                const tableColumnNames = contractsTableData.columns
                  .map((column) => column.field)
                  .join(',')

                const contractParams = {
                  id: dropDownSelection.id,
                  name: dropDownSelection.name,
                  url_params: contractsQueryParams,
                  selection_params: JSON.stringify(contractsFilterParams),
                  column_params: tableColumnNames,
                }
                await dispatch(updateContractView(contractParams, {}))
                await dispatch(fetchContractViews())
              }}
            >
              Update View
            </MDBBtn>

            {/* CREATE-VIEW */}
            <MDBBtn
              size="sm"
              color="secondary"
              disabled={hasQueryParams}
              onClick={(e) => toggleModal(e, CREATE_FLAG)}
            >
              Create View
            </MDBBtn>

            {/* MAKE-DEFAULT-VIEW */}
            <MDBBtn
              className={defaultActiveClass}
              size="sm"
              color="secondary"
              disabled={_.isEmpty(dropDownSelection)}
              onClick={async (e) => {
                e.preventDefault()

                setDropDownSelection({
                  ...dropDownSelection,
                  is_primary: true,
                })
                const params = {
                  id: dropDownSelection.id,
                  is_primary: true,
                }

                await dispatch(updateContractView(params, dropDownSelection))
              }}
            >
              Make Default
            </MDBBtn>

            {/* DELETE-VIEW */}
            <MDBBtn
              disabled={_.isEmpty(dropDownSelection)}
              size="sm"
              color="secondary"
              onClick={(e) => toggleModal(e, DELETE_FLAG)}
            >
              Delete View
            </MDBBtn>
          </>
        ) : (
          void 0
        )}

        {/* REFRESH-VIEW */}
        <MDBBtn
          className={defaultActiveClass}
          size="sm"
          color="secondary"
          onClick={() => {
            setContractsReload(true)
          }}
        >
          Refresh
        </MDBBtn>
      </MDBCol>

      {/* CONTRACT-VIEW-CONTROLS-MODAL */}
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={true}
        actionButtons={actions}
      >
        {headerText === CREATE_HEADER ? (
          <form
            id={formId}
            className="form"
            noValidate
            onSubmit={async (e) => {
              e.preventDefault()

              const tableColumnNames = contractsTableData.columns
                .map((column) => column.field)
                .join(',')

              const contractViewParams = {
                name: newViewName,
                url_params: contractsQueryParams,
                selection_params: JSON.stringify(contractsFilterParams),
                column_params: tableColumnNames,
              }
              await dispatch(createContractView(contractViewParams))
              await dispatch(fetchContractViews())
              setNewViewName('')
              toggleModal()
            }}
          >
            <MDBInput
              value={newViewName}
              getValue={(e) => {
                setNewViewName(e)
              }}
              id="name"
              label="Name"
              outline
              containerClass="required-label"
              required
              className="input-select"
            />
          </form>
        ) : headerText === DELETE_HEADER ? (
          <form
            id={formId}
            className="form"
            noValidate
            onSubmit={async (e) => {
              e.preventDefault()

              const contractViewParams = {
                id: dropDownSelection.id,
              }

              await dispatch(deleteContractView(contractViewParams))
              await dispatch(fetchContractViews())
              setNewViewName('')
              toggleModal()
            }}
          >
            <span>
              Are you sure you want to delete the view{' '}
              {
                contractViewsAvailable?.[dropDownSelection?.id?.toString()]
                  ?.name
              }
              ?
            </span>
          </form>
        ) : (
          void 0
        )}
      </Modal>
    </MDBRow>
  )
}

export default ContractView
