import {
  SET_CONTRACT_SUBLOCATIONS,
  SET_CONTRACT_SUBLOCATION_OPTIONS,
  UNSET_CONTRACT_SUBLOCATION_OPTIONS,
  CREATE_CONTRACT_SUBLOCATION,
  DELETE_CONTRACT_SUBLOCATION,
  UNSET_CONTRACT_SUBLOCATION_ACTION,
  UPDATE_CONTRACT_SUBLOCATION,
  TOGGLE_CONTRACT_SUBLOCATION,
  ERROR_CONTRACT_SUBLOCATION,
  UNSET_ERROR_CONTRACT_SUBLOCATION,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { filterByLocation } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  sublocationsAvailable: {},
  sublocationOptions: [],
  sublocationErrors: {},
  cachedResponseData: null,
}

const contractSublocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_SUBLOCATIONS:
      return {
        ...state,
        lastAction: 'fetch',
        sublocationsAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_CONTRACT_SUBLOCATION_OPTIONS:
      const options = selectObject(
        filterByLocation(action.payload, action.currentContractLocationId),
        action.currentContractSublocation
          ? action.currentContractSublocation.id
          : 0
      )
      return { ...state, sublocationOptions: options }
    case UNSET_CONTRACT_SUBLOCATION_OPTIONS:
      if (state.sublocationOptions) {
        return { ...state, sublocationOptions: [] }
      }
      return state
    case UNSET_CONTRACT_SUBLOCATION_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_SUBLOCATION:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        sublocationsAvailable: {
          ...state.sublocationsAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_SUBLOCATION:
      const updatedItem = (state.sublocationsAvailable[
        action.currentContractSublocation.id
      ] = action.currentContractSublocation)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_SUBLOCATION:
      return {
        ...state,
        sublocationErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_SUBLOCATION:
      return {
        ...state,
        sublocationErrors: {},
      }
    case TOGGLE_CONTRACT_SUBLOCATION:
      const toggledItem = (state.sublocationsAvailable[
        action.currentContractSublocation.id
      ] = action.currentContractSublocation)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_CONTRACT_SUBLOCATION:
      const updated = state.sublocationsAvailable
      _.unset(updated, `${action.currentContractSublocation.id}`)
      return {
        ...state,
        lastAction: 'delete',
        sublocationsAvailable: updated,
        cachedResponseData: null,
      }
    default:
      return state
  }
}

export default contractSublocationReducer
