import {
  fetchContractLocations,
  clearContractLocationOptions,
} from 'actions/contracts/contractLocationActions'
import {
  updateContractSublocation,
  clearContractSublocationAction,
  clearContractSublocationErrors,
} from 'actions/contracts/contractSublocationActions'
import AdminForm from 'components/admin/AdminForm'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ContractSublocationUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected,
  setSelected,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { locationOptions } = useSelector((state) => state.contractLocations)
  const { lastAction, sublocationErrors } = useSelector(
    (state) => state.contractSublocations
  )

  //// LOCAL STATE.
  const [locationOptionsBuffered, setLocationOptionsBuffered] = useState([])

  //// LIFECYCLE HELPERS.

  //// MEMOS.
  // this is used to prevent some strange lifecycle behavior where the locationOptions are emptied out any time the selected location changes.
  // its faster to fix it this way then debug the real reason for the issue buried somewhere in the redux code.
  useEffect(() => {
    // don't unset the locationOptions
    if (locationOptions.length) {
      setLocationOptionsBuffered((prev) => {
        // if the lengths don't match use the new options
        if (prev.length !== locationOptions.length) {
          return locationOptions
        }

        // if the arrays don't match, use the new options
        if (locationOptions.some(({ id }, idx) => id !== prev[idx].id)) {
          return locationOptions
        }

        return prev
      })
    }
  }, [locationOptions, setLocationOptionsBuffered])

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractLocations('form', selected.contract_location, true))
    return () => {
      dispatch(clearContractLocationOptions())
      dispatch(clearContractSublocationAction())
      dispatch(clearContractSublocationErrors())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selected.contract_location])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        name: selected.name,
        contract_location_id: selected.contract_location.id,
      })
    }
  }, [params, setParams, selected])

  useEffect(() => {
    if (lastAction === 'edit') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) || !locationOptionsBuffered.length ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        dispatch(updateContractSublocation(params, selected))
      }}
      className="form container"
      noValidate
    >
      <AdminForm
        params={params}
        setParams={setParams}
        selected={selected}
        setSelected={setSelected}
        contractLocations={locationOptionsBuffered}
        errors={sublocationErrors}
      />
    </form>
  )
}

export default ContractSublocationUpdate
