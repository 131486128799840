import ContractActivityForm from '../forms/ContractActivityForm'
import {
  updateContractActivity,
  clearContractActivityErrors,
  clearContractActivityAction,
} from 'actions/contracts/contractActivityActions'
import { useContractLocations } from 'hooks/api/useContractLocations'
import { useContractStatuses } from 'hooks/api/useContractStatuses'
import { useContractSublocations } from 'hooks/api/useContractSublocations'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ContractActivityUpdate = ({
  formId,
  params,
  setParams,
  selected,
  setSelected,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, activityErrors } = useSelector(
    (state) => state.contractActivities
  )

  //// QUERIES.
  const { options: statusOptions } = useContractStatuses(
    selected.contract_status.id
  )
  const { options: locationOptions } = useContractLocations(
    selected.contract_location.id
  )
  const { options: sublocationOptions } = useContractSublocations(
    selected.contract_location.id,
    selected.contract_sublocation.id
  )

  //// LOCAL STATE.
  const [occurDate, setOccurDate] = useState(moment())
  const [occurTime, setOccurTime] = useState(moment())

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearContractActivityAction())
      dispatch(clearContractActivityErrors())
    }
  }, [dispatch])

  useEffect(() => {
    if (selected.contract_location) {
      setSelected((x) => ({
        ...x,
        contract_sublocation: { id: null, name: null },
      }))
      setParams((x) => ({ ...x, contract_sublocation_id: null }))
    }
  }, [dispatch, selected.contract_location, setParams, setSelected])

  useEffect(() => {
    if (
      _.isEmpty(params) &&
      (Array.isArray(statusOptions) || statusOptions.length) &&
      (Array.isArray(locationOptions) || locationOptions.length)
    ) {
      const utcTime = moment
        .utc(selected.activity_occurred_at)
        .format('YYYY-MM-DD HH:mm:ssZ')
      const localTime = moment(utcTime, 'YYYY-MM-DD HH:mm:ssZ').local()
      setOccurDate(localTime)
      setOccurTime(localTime)
      setParams({
        id: selected.id,
        contract_status_id: selected.contract_status.id || 0,
        contract_location_id: selected.contract_location.id,
        contract_sublocation_id: selected.contract_sublocation.id,
        comment: selected.comment,
        activity_occurred_at: selected.activity_occurred_at,
      })
      setSelected({
        ...selected,
        id: selected.id,
        customer_id: selected.customer_id,
      })
    }
  }, [
    selected,
    setSelected,
    params,
    setParams,
    setOccurDate,
    setOccurTime,
    statusOptions,
    locationOptions,
  ])

  // watch for success
  useEffect(() => {
    if (lastAction === 'edit') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        dispatch(updateContractActivity(params, selected))
      }}
      className="form container"
      noValidate
    >
      <ContractActivityForm
        params={params}
        setParams={setParams}
        selected={selected}
        setSelected={setSelected}
        statusOptions={statusOptions}
        locationOptions={locationOptions}
        sublocationOptions={sublocationOptions}
        occurDate={occurDate}
        setOccurDate={setOccurDate}
        occurTime={occurTime}
        setOccurTime={setOccurTime}
        errors={activityErrors}
      />
    </form>
  )
}

export default ContractActivityUpdate
