import SideNavCanvas from './SideNavCanvas'
import SideNavDesktop from './SideNavDesktop'
import { css } from '@emotion/core'
import { MDBSideNavCat, MDBSideNavNav, MDBSideNavLink } from 'mdbreact'
import { lighten, darken } from 'polished'
import React from 'react'
import { isUserGuest } from 'utils/authUtils'

const SideNav = ({ auth, size, theme, logo, isOpen }) => {
  const hasSiteReadiness = auth.user?.customer?.has_site_readiness

  const sideBarStyles = css`
    background-color: ${theme.colors.secondary};
    padding-bottom: 0 !important;

    .collapsible {
      margin-top: 2px;

      > li a.collapsible-header {
        :hover {
          background-color: ${lighten(0.25, theme.colors.secondary)};
        }
        &.active {
          background-color: ${lighten(0.1, theme.colors.secondary)};
        }
      }
      a.active {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.lightest};
        &:hover {
          background-color: ${darken(0.1, theme.colors.primary)};
          color: ${darken(0.1, theme.colors.lightest)};
        }
      }
    }

    .logo-wrapper,
    .logo-wrapper a {
      height: 66px;
      padding: 0;
    }

    .logo-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: none;

      img {
        height: 40px;
        padding 2px;
      }
    }
  `

  if (auth.user) {
    const sideNavLinks = [
      {
        name: 'Home',
        url: '/',
        icon: 'home',
        exact: true,
        show: true,
      },
      {
        name: 'Reports',
        url: '/reports',
        icon: 'chart-pie',
        exact: true,
        show: true,
      },
      {
        name: 'Contracts',
        icon: 'file-contract',
        url: '/contracts',
        exact: true,
        show: true,
      },
      ...(hasSiteReadiness
        ? [
            {
              name: 'Site Readiness',
              icon: 'city',
              url: '/sr-documents',
              exact: true,
              show: true,
            },
          ]
        : []),
      {
        name: 'Studies',
        icon: 'book-medical',
        iconClassName: 'fas',
        url: '/studies',
        exact: true,
        show: true,
      },
      {
        name: 'Contacts',
        icon: 'address-book',
        url: '/contacts',
        exact: true,
        show: !isUserGuest(auth?.user?.user_level),
      },
      {
        name: 'Sites',
        icon: 'building',
        url: '/sites',
        exact: true,
        show: !isUserGuest(auth?.user?.user_level),
      },
    ]
    if (auth.user.is_admin) {
      sideNavLinks.push({
        name: 'Admin',
        icon: 'user-cog',
        show: true,
        children: [
          {
            name: 'Users',
            url: '/admin/users',
            exact: true,
          },
          {
            name: 'Data Imports',
            url: '/admin/imports',
            exact: true,
          },
          {
            name: 'Budget Visits',
            url: '/admin/budget',
            exact: true,
          },
          {
            name: 'Study Budget Visits',
            url: '/admin/budget/studies',
          },
          {
            name: 'Contract Types',
            url: '/admin/contracts/types',
          },
          {
            name: 'Contract Subtypes',
            url: '/admin/contracts/subtypes',
          },
          {
            name: 'Contract Statuses',
            url: '/admin/contracts/statuses',
          },
          {
            name: 'Contract Locations',
            url: '/admin/contracts/locations',
          },
          {
            name: 'Contract Sublocations',
            url: '/admin/contracts/sublocations',
          },
          {
            name: 'Contract Document Types',
            url: '/admin/contracts/document-types',
          },
          {
            name: 'Contract Activity Alerts',
            url: '/admin/contracts/activity-alerts',
          },
          ...(hasSiteReadiness
            ? [
                {
                  name: 'Site Readiness Types',
                  url: '/admin/sr-documents/types',
                },
                {
                  name: 'Site Readiness Subtypes',
                  url: '/admin/sr-documents/subtypes',
                },
                {
                  name: 'Site Readiness Statuses',
                  url: '/admin/sr-documents/statuses',
                },
                {
                  name: 'Site Readiness Locations',
                  url: '/admin/sr-documents/locations',
                },
                {
                  name: 'Site Readiness File Types',
                  url: '/admin/sr-documents/document-types',
                },
              ]
            : []),
          {
            name: 'Custom Properties',
            exact: true,
            url: '/admin/properties',
          },
          {
            name: 'Contract Properties',
            url: '/admin/properties/contracts',
          },
          ...(hasSiteReadiness
            ? [
                {
                  name: 'Site Readiness Properties',
                  url: '/admin/properties/sr-documents',
                },
              ]
            : []),
          {
            name: 'Study Properties',
            url: '/admin/properties/studies',
          },
          {
            name: 'Contact Properties',
            url: '/admin/properties/contacts',
          },
          {
            name: 'Site Properties',
            url: '/admin/properties/sites',
          },
          {
            name: 'Invoice Milestones',
            url: '/admin/invoice-milestones',
          },
        ],
      })
    }

    const renderUserLinks = (linkItems) => {
      return linkItems
        .filter((item) => item.show)
        .map((item, pIndex) => {
          if (item.children) {
            const smallName = item.name.toLowerCase()
            return (
              <MDBSideNavCat
                name={item.name}
                id={smallName}
                className={`sidenav-${smallName}`}
                icon={item.icon}
                key={`parent-${pIndex}`}
              >
                {item.children.map((child, cIndex) => (
                  <li key={`child-${cIndex}`}>
                    <MDBSideNavLink
                      exact={child.exact}
                      className="sidenav-child-link"
                      to={child.url}
                    >
                      {child.name}
                    </MDBSideNavLink>
                  </li>
                ))}
              </MDBSideNavCat>
            )
          }
          return (
            <MDBSideNavLink
              exact={item.exact}
              to={item.url}
              topLevel
              key={`parent-${pIndex}`}
            >
              <i className={`fa fa-${item.icon} fa-fw mr-2`}></i>
              {item.name}
            </MDBSideNavLink>
          )
        })
    }

    if (size.width >= theme.screens.xlarge) {
      return (
        <SideNavDesktop logo={logo} styles={sideBarStyles}>
          <MDBSideNavNav>{renderUserLinks(sideNavLinks)}</MDBSideNavNav>
        </SideNavDesktop>
      )
    } else if (size.width < theme.screens.xlarge) {
      return (
        <SideNavCanvas logo={logo} styles={sideBarStyles} toggle={isOpen}>
          <MDBSideNavNav>{renderUserLinks(sideNavLinks)}</MDBSideNavNav>
        </SideNavCanvas>
      )
    }
    return ''
  }
  return ''
}

export default SideNav
