import BudgetVisitList from 'components/admin/budgets/BudgetVisitList'
import StudyBudgetVisitList from 'components/admin/budgets/studies/StudyBudgetVisitList'
import ContractActivityAlertCreate from 'components/admin/contracts/activityAlerts/ContractActivityAlertCreate'
import ContractActivityAlertList from 'components/admin/contracts/activityAlerts/ContractActivityAlertList'
import ContractActivityAlertShow from 'components/admin/contracts/activityAlerts/ContractActivityAlertShow'
import ContractActivityAlertUpdate from 'components/admin/contracts/activityAlerts/ContractActivityAlertUpdate'
import ContractDocumentTypeList from 'components/admin/contracts/documentTypes/ContractDocumentTypeList'
import ContractLocationList from 'components/admin/contracts/locations/ContractLocationList'
import ContractStatusList from 'components/admin/contracts/statuses/ContractStatusList'
import ContractSublocationList from 'components/admin/contracts/sublocations/ContractSublocationList'
import ContractSubtypeList from 'components/admin/contracts/subtypes/ContractSubtypeList'
import ContractTypeList from 'components/admin/contracts/types/ContractTypeList'
import DataImportList from 'components/admin/imports/DataImportList'
import DataImportShow from 'components/admin/imports/DataImportShow'
import InvoiceMilestonesList from 'components/admin/invoiceMilestones/InvoiceMilestonesList'
import CustomPropertyList from 'components/admin/properties/CustomPropertyList'
import ContactPropertyList from 'components/admin/properties/contacts/ContactPropertyList'
import ContractPropertyList from 'components/admin/properties/contracts/ContractPropertyList'
import CustomPropertyListItemList from 'components/admin/properties/listItems/CustomPropertyListItemList'
import SitePropertyList from 'components/admin/properties/sites/SitePropertyList'
import SrDocumentPropertyList from 'components/admin/properties/srDocuments/SrDocumentPropertyList'
import StudyPropertyList from 'components/admin/properties/studies/StudyPropertyList'
import SrDocumentDocTypeList from 'components/admin/srDocuments/docTypes/SrDocumentDocTypeList'
import SrDocumentLocationList from 'components/admin/srDocuments/locations/SrDocumentLocationList'
import SrDocumentStatusList from 'components/admin/srDocuments/statuses/SrDocumentStatusList'
import SrDocumentSubtypeList from 'components/admin/srDocuments/subtypes/SrDocumentSubtypeList'
import SrDocumentTypeList from 'components/admin/srDocuments/types/SrDocumentTypeList'
import UserGuestStudiesManagement from 'components/admin/users/UserGuestStudiesManagement'
import UserList from 'components/admin/users/UserList'
import UserShow from 'components/admin/users/UserShow'
import { useAuth } from 'hooks/useAuth.js'
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

const AdminRoutes = () => {
  const auth = useAuth()
  const hasSiteReadiness = auth?.user?.customer?.has_site_readiness

  // Non Admin Users Should Not See non Admin Routes
  if (auth.hasUserInfo && !auth?.user?.is_admin) {
    return <Redirect to="/" />
  }
  return (
    <Switch>
      <Route exact path="/admin/users" component={UserList} />
      <Route exact path="/admin/users/:id" component={UserShow} />
      <Route exact path="/admin/imports" component={DataImportList} />
      <Route exact path="/admin/imports/:id" component={DataImportShow} />
      <Route exact path="/admin/budget" component={BudgetVisitList} />
      <Route
        exact
        path="/admin/budget/studies"
        component={StudyBudgetVisitList}
      />
      <Route
        exact
        path="/admin/contracts/document-types"
        component={ContractDocumentTypeList}
      />
      <Route
        exact
        path="/admin/contracts/locations"
        component={ContractLocationList}
      />
      <Route
        exact
        path="/admin/contracts/sublocations"
        component={ContractSublocationList}
      />
      <Route exact path="/admin/contracts/types" component={ContractTypeList} />
      <Route
        exact
        path="/admin/contracts/subtypes"
        component={ContractSubtypeList}
      />
      <Route
        exact
        path="/admin/contracts/statuses"
        component={ContractStatusList}
      />
      <Route
        exact
        path="/admin/contracts/activity-alerts"
        component={ContractActivityAlertList}
      />
      <Route
        exact
        path="/admin/contracts/activity-alerts/new"
        component={ContractActivityAlertCreate}
      />
      <Route
        exact
        path="/admin/contracts/activity-alerts/:id"
        component={ContractActivityAlertShow}
      />
      <Route
        exact
        path="/admin/contracts/activity-alerts/:id/edit"
        component={ContractActivityAlertUpdate}
      />

      {/* srDocuments */}
      <Route
        exact
        path="/admin/sr-documents/types"
        component={hasSiteReadiness && SrDocumentTypeList}
      />
      <Route
        exact
        path="/admin/sr-documents/subtypes"
        component={hasSiteReadiness && SrDocumentSubtypeList}
      />
      <Route
        exact
        path="/admin/sr-documents/statuses"
        component={hasSiteReadiness && SrDocumentStatusList}
      />
      <Route
        exact
        path="/admin/sr-documents/locations"
        component={hasSiteReadiness && SrDocumentLocationList}
      />
      <Route
        exact
        path="/admin/sr-documents/document-types"
        component={hasSiteReadiness && SrDocumentDocTypeList}
      />
      <Route
        exact
        path="/admin/properties/sr-documents"
        component={hasSiteReadiness && SrDocumentPropertyList}
      />

      <Route exact path="/admin/users" component={UserList} />
      <Route exact path="/admin/users/:id" component={UserShow} />
      <Route
        exact
        path="/admin/users/:id/studies"
        component={UserGuestStudiesManagement}
      />
      <Route exact path="/admin/properties" component={CustomPropertyList} />
      <Route
        exact
        path="/admin/properties/contacts"
        component={ContactPropertyList}
      />
      <Route
        exact
        path="/admin/properties/contracts"
        component={ContractPropertyList}
      />
      <Route
        exact
        path="/admin/properties/sites"
        component={SitePropertyList}
      />
      <Route
        exact
        path="/admin/properties/studies"
        component={StudyPropertyList}
      />
      <Route
        exact
        path="/admin/properties/:id"
        component={CustomPropertyListItemList}
      />
      <Route
        exact
        path="/admin/invoice-milestones"
        component={InvoiceMilestonesList}
      />
    </Switch>
  )
}

export default AdminRoutes
