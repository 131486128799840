import _ from 'lodash'

export const setContractColumns = (params, properties) => {
  return params.map((param) => {
    if (isNaN(param)) {
      switch (param) {
        case 'id':
          return {
            field: param,
            label: '#',
            sort: 'desc',
          }
        case 'current_state_text':
          return {
            field: param,
            label: 'State',
            sort: 'desc',
          }
        case 'study.name':
          return {
            field: param,
            label: 'Sponsor Study Name',
            sort: 'desc',
          }
        case 'contract_type.name':
          return {
            field: param,
            label: 'Type',
            sort: 'desc',
          }
        case 'contract_subtype.name':
          return {
            field: param,
            label: 'Subtype',
            sort: 'desc',
          }
        case 'current_activity.contract_status':
          return {
            field: param,
            label: 'Current Status',
            sort: 'desc',
          }
        case 'current_activity.contract_location':
          return {
            field: param,
            label: 'Current Location',
            sort: 'desc',
          }
        case 'current_activity.contract_sublocation':
          return {
            field: param,
            label: 'Current Sublocation',
            sort: 'desc',
          }
        case 'current_activity.activity_occurred_at':
          return {
            date: true,
            field: param,
            label: 'Last Active (UTC)',
            sort: 'desc',
          }
        case 'buttons':
          return {
            field: param,
            label: '',
            minimal: 'lg',
            sort: 'disabled',
          }
        default:
          return false
      }
    } else {
      const propertyResult = _.find(
        properties.custom_properties,
        (property) => {
          if (property.property.id === Number(param)) {
            return true
          }
        }
      )
      return propertyResult
        ? {
            field: propertyResult.property.id.toString(),
            isCustom: true,
            label: propertyResult.property.name,
            path: `custom_properties['${propertyResult.property.name}']`,
          }
        : false
    }
  })
}

export const setSrDocumentColumns = (params, properties) => {
  return params.map((param) => {
    if (isNaN(param)) {
      switch (param) {
        case 'id':
          return {
            field: param,
            label: '#',
            sort: 'desc',
          }
        case 'current_state_text':
          return {
            field: param,
            label: 'State',
            sort: 'desc',
          }
        case 'study.name':
          return {
            field: param,
            label: 'Sponsor Study Name',
            sort: 'desc',
          }
        case 'sr_document_type.name':
          return {
            field: param,
            label: 'Type',
            sort: 'desc',
          }
        case 'sr_document_subtype.name':
          return {
            field: param,
            label: 'Subtype',
            sort: 'desc',
          }
        case 'current_activity.sr_document_status':
          return {
            field: param,
            label: 'Current Status',
            sort: 'desc',
          }
        case 'current_activity.sr_document_location':
          return {
            field: param,
            label: 'Current Location',
            sort: 'desc',
          }
        case 'current_activity.activity_occurred_at':
          return {
            date: true,
            field: param,
            label: 'Last Active (UTC)',
            sort: 'desc',
          }
        case 'buttons':
          return {
            field: param,
            label: '',
            minimal: 'lg',
            sort: 'disabled',
          }
        default:
          return false
      }
    } else {
      const propertyResult = _.find(
        properties.custom_properties,
        (property) => {
          if (property.property.id === Number(param)) {
            return true
          }
        }
      )
      return propertyResult
        ? {
            field: propertyResult.property.id.toString(),
            isCustom: true,
            label: propertyResult.property.name,
            path: `custom_properties['${propertyResult.property.name}']`,
          }
        : false
    }
  })
}

export const filterTableColumns = (tableData, setTableData, columnNames) => {
  if (tableData.columns.find((c) => columnNames.includes(c.field))) {
    tableData.columns = tableData.columns.filter((c) => {
      return !columnNames.includes(c.field)
    })
    setTableData(tableData)
  }
}

export const defaultContractColumns = [
  {
    label: '#',
    field: 'id',
    sort: 'desc',
    defaultActive: true,
  },
  {
    label: 'State',
    field: 'current_state_text',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Sponsor Study Name',
    field: 'study.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Country',
    field: 'primary_site_country.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Site',
    field: 'primary_site.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Investigator',
    field: 'primary_investigator.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Type',
    field: 'contract_type.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Subtype',
    field: 'contract_subtype.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Current Status',
    field: 'current_activity.contract_status',
    sort: 'desc',
    defaultActive: true,
  },
  {
    label: 'Current Location',
    field: 'current_activity.contract_location',
    sort: 'desc',
    defaultActive: true,
  },
  {
    label: 'Current Sublocation',
    field: 'current_activity.contract_sublocation',
    sort: 'desc',
    defaultActive: true,
  },
  {
    label: 'Last Active (UTC)',
    field: 'current_activity.activity_occurred_at',
    date: true,
    sort: 'desc',
    minimal: 'lg',
    defaultActive: true,
  },
]

export const defaultSrDocumentColumns = [
  {
    label: '#',
    field: 'id',
    sort: 'desc',
    defaultActive: true,
  },
  /*
  this is being hidden at clients request.
  {
    label: 'State',
    field: 'current_state_text',
    sort: 'asc',
    defaultActive: true,
  },
  */
  {
    label: 'Sponsor Study Name',
    field: 'study.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Country',
    field: 'primary_site_country.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Site',
    field: 'primary_site.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Investigator',
    field: 'primary_investigator.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Type',
    field: 'sr_document_type.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Subtype',
    field: 'sr_document_subtype.name',
    sort: 'asc',
    defaultActive: true,
  },
  {
    label: 'Current Status',
    field: 'current_activity.sr_document_status',
    sort: 'desc',
    defaultActive: true,
  },
  {
    label: 'Current Location',
    field: 'current_activity.sr_document_location',
    sort: 'desc',
    defaultActive: true,
  },
  {
    label: 'Last Active (UTC)',
    field: 'current_activity.activity_occurred_at',
    date: true,
    sort: 'desc',
    minimal: 'lg',
    defaultActive: true,
  },
]
