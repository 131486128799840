import DateFnsUtils from '@date-io/moment'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import _ from 'lodash'
// choose your lib
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers'
import { MDBRow, MDBCol, MDBInput, MDBFileInput } from 'mdbreact'
import moment from 'moment'
import React from 'react'
import { inputErrorClass } from 'utils/formUtils'

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#f88b5e',
      dark: '#f88b5e',
    },
    secondary: {
      main: '#2d3848',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    useNextVariants: true,
  },
})

const statusDefaultOption = 'Choose A Status'
const locationDefaultOption = 'Choose A Location'
const sublocationDefaultOption = 'Choose A Sublocation'
const documentTypeDefaultOption = 'Choose A Document Type'

const ContractActivityForm = ({
  params,
  setParams,
  selected,
  setSelected,
  statusOptions,
  locationOptions,
  sublocationOptions,
  documentTypeOptions,
  occurDate,
  setOccurDate,
  occurTime,
  setOccurTime,
  errors,
}) => {
  //// HOOKS.

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <MDBRow>
      <MDBCol md="12">
        <Select
          id="select__statuses"
          options={statusOptions}
          getTextContent={(e) => {
            if (e !== statusDefaultOption) {
              const currentStatus = statusOptions.filter(
                (status) => status.value === e
              )
              setParams({
                ...params,
                contract_status_id: `${currentStatus[0].id}`,
              })
              if (
                selected &&
                selected.contract_status.id !== currentStatus[0].id
              ) {
                setSelected({
                  ...selected,
                  contract_status: {
                    id: currentStatus[0].id,
                    name: currentStatus[0].value,
                  },
                })
              }
            }
          }}
          label="Status"
          defaultOption={statusDefaultOption}
          additionalClasses="mb-0"
          search
          required
          errors={errors}
        />
      </MDBCol>
      <MDBCol md="12">
        <Select
          id="select__locations"
          options={locationOptions}
          getTextContent={(e) => {
            if (e !== locationDefaultOption) {
              const currentLocation = locationOptions.filter(
                (location) => location.value === e
              )
              setParams({
                ...params,
                contract_location_id: `${currentLocation[0].id}`,
              })
              if (
                selected &&
                selected.contract_location.id !== currentLocation[0].id
              ) {
                setSelected({
                  ...selected,
                  contract_location: {
                    id: currentLocation[0].id,
                    name: currentLocation[0].value,
                  },
                })
              }
            }
          }}
          label="Location"
          defaultOption={locationDefaultOption}
          additionalClasses="mb-0"
          search
          required
          errors={errors}
        />
      </MDBCol>
      <MDBCol md="12">
        <Select
          id="select__sublocations"
          options={sublocationOptions}
          getTextContent={(e) => {
            if (e !== sublocationDefaultOption) {
              const currentLocation = sublocationOptions.filter(
                (location) => location.value === e
              )
              setParams({
                ...params,
                contract_sublocation_id: `${currentLocation[0].id}`,
              })
              if (
                selected &&
                selected.contract_sublocation.id !== currentLocation[0].id
              ) {
                setSelected({
                  ...selected,
                  contract_sublocation: {
                    id: currentLocation[0].id,
                    name: currentLocation[0].value,
                  },
                })
              }
            }
          }}
          label="Sublocation"
          defaultOption={sublocationDefaultOption}
          additionalClasses="mb-0"
          search
          required
          errors={errors}
        />
      </MDBCol>
      <MDBCol md="12">
        <MDBInput
          value={params.comment}
          getValue={(e) => {
            setParams({ ...params, comment: e })
            if (selected && selected.comment !== e) {
              setSelected({ ...selected, comment: e })
            }
          }}
          id="comment-input"
          label="Comment"
          type="textarea"
          rows="3"
          outline
          required
          containerClass="required-label"
          className={`md-textarea mb-0 input-select${inputErrorClass(
            errors,
            !params.comment
          )}`}
        />
      </MDBCol>
      {selected ? (
        ''
      ) : (
        <MDBCol md="12">
          <MDBFileInput
            textFieldTitle="Upload a document"
            btnColor="primary"
            getValue={(v) => {
              setParams({ ...params, import_file: v[0] })
              if (selected && !_.isEqual(selected.import_file, v[0])) {
                setSelected({ ...selected, import_file: v[0] })
              }
            }}
          />
        </MDBCol>
      )}
      {selected ? (
        ''
      ) : (
        <MDBCol md="12">
          <Select
            id="select__document-types"
            options={documentTypeOptions}
            getTextContent={(v) => {
              if (v !== documentTypeDefaultOption) {
                const currentDocumentType = documentTypeOptions.filter(
                  (documentType) => documentType.value === v
                )
                setParams({
                  ...params,
                  contract_document_type_id: `${currentDocumentType[0].id}`,
                })
                if (
                  selected &&
                  selected.contract_document_type.id !==
                    currentDocumentType[0].id
                ) {
                  setSelected({
                    ...selected,
                    contract_document_type: {
                      id: currentDocumentType[0].id,
                      name: currentDocumentType[0].value,
                    },
                  })
                }
              }
            }}
            label="Document Type"
            defaultOption={documentTypeDefaultOption}
            additionalClasses="mb-0"
            search
            required
            disabled={!params.import_file}
            errors={errors}
          />
        </MDBCol>
      )}
      <MDBCol md="12">
        <h6>Date Occurred</h6>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={materialTheme}>
            <MDBRow>
              <MDBCol md="6">
                <DatePicker
                  className="picker"
                  format="MMMM Do, YYYY"
                  value={occurDate.toDate()}
                  onChange={(value) => {
                    if (moment(value).toString() !== occurDate.toString()) {
                      if (setOccurDate) setOccurDate(moment(value))
                      const currentDate = moment(value).format('YYYY-MM-DD')
                      const currentTime = moment(occurTime, 'hh:mmA').format(
                        'hh:mmA'
                      )
                      const timeToUTC = moment(
                        `${currentDate} ${currentTime}`,
                        'YYYY-MM-DD hh:mmA'
                      ).toISOString()

                      setParams({
                        ...params,
                        activity_occurred_at: timeToUTC.split('.')[0],
                      })
                      if (
                        selected &&
                        moment(
                          selected,
                          'YYYY-MM-DD HH:mm:ss'
                        ).toISOString() !== timeToUTC
                      ) {
                        setSelected({
                          ...selected,
                          activity_occurred_at: moment
                            .utc(timeToUTC)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        })
                      }
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md="6">
                <TimePicker
                  className="picker"
                  value={occurTime.toDate()}
                  onChange={(value) => {
                    if (value.format('hh:mmA') !== occurTime.format('hh:mmA')) {
                      if (setOccurTime) setOccurTime(value)
                      const currentDate = occurDate.format('YYYY-MM-DD')
                      const currentTime = moment(value, 'hh:mmA').format(
                        'hh:mmA'
                      )
                      const timeToUTC = moment(
                        `${currentDate} ${currentTime}`,
                        'YYYY-MM-DD hh:mmA'
                      ).toISOString()

                      setParams({
                        ...params,
                        activity_occurred_at: timeToUTC.split('.')[0],
                      })

                      if (
                        selected &&
                        moment(
                          selected,
                          'YYYY-MM-DD HH:mm:ss'
                        ).toISOString() !== timeToUTC
                      ) {
                        setSelected({
                          ...selected,
                          activity_occurred_at: moment
                            .utc(timeToUTC)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        })
                      }
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </MDBCol>
      <ErrorPanel results={errors} />
    </MDBRow>
  )
}

export default ContractActivityForm
