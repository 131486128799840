import {
  SET_CONTRACT_SUBLOCATIONS,
  SET_CONTRACT_SUBLOCATION_OPTIONS,
  UNSET_CONTRACT_SUBLOCATION_OPTIONS,
  CREATE_CONTRACT_SUBLOCATION,
  DELETE_CONTRACT_SUBLOCATION,
  UPDATE_CONTRACT_SUBLOCATION,
  TOGGLE_CONTRACT_SUBLOCATION,
  ERROR_CONTRACT_SUBLOCATION,
  UNSET_CONTRACT_SUBLOCATION_ACTION,
  UNSET_ERROR_CONTRACT_SUBLOCATION,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractSublocationEndPoint = '/contracts/sublocation'

// Passing data back to reducers
const setContractSublocation = (
  obj,
  type,
  contractLocationId,
  contractSublocation
) => {
  const action = { type: type, payload: obj }
  if (contractLocationId) action.currentContractLocationId = contractLocationId
  if (contractSublocation)
    action.currentContractSublocation = contractSublocation
  return action
}

// Non-API calls
export const clearContractSublocationOptions = () => async (dispatch) => {
  dispatch(setContractSublocation({}, UNSET_CONTRACT_SUBLOCATION_OPTIONS))
}
export const clearContractSublocationAction = () => async (dispatch) => {
  dispatch(setContractSublocation({}, UNSET_CONTRACT_SUBLOCATION_ACTION))
}
export const clearContractSublocationErrors = () => async (dispatch) => {
  dispatch(setContractSublocation({}, UNSET_ERROR_CONTRACT_SUBLOCATION))
}

// API calls
export const fetchContractSublocations =
  (results, contractLocationId, contractSublocation) => async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(contractSublocationEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setContractSublocation(res.data, SET_CONTRACT_SUBLOCATIONS))
      } else if (results === 'form') {
        dispatch(
          setContractSublocation(
            res.data,
            SET_CONTRACT_SUBLOCATION_OPTIONS,
            contractLocationId,
            contractSublocation
          )
        )
      }
    }
  }

export const createContractSublocation = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractSublocationEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractSublocation(res.data, CREATE_CONTRACT_SUBLOCATION))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractSublocation(error, ERROR_CONTRACT_SUBLOCATION))
    }
  }
}

export const deleteContractSublocation = (sublocation) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractSublocationEndPoint, {
      data: { id: sublocation.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setContractSublocation(
        res.data,
        DELETE_CONTRACT_SUBLOCATION,
        null,
        sublocation
      )
    )
  }
}

export const updateContractSublocation =
  (params, sublocation) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractSublocationEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setContractSublocation(
            res.data,
            UPDATE_CONTRACT_SUBLOCATION,
            null,
            sublocation
          )
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractSublocation(error, ERROR_CONTRACT_SUBLOCATION))
      }
    }
  }

export const toggleContractSublocation =
  (sublocation, toggle) => async (dispatch) => {
    const toggleEndPoint = `${contractSublocationEndPoint}/${
      toggle ? 'enable' : 'disable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: sublocation.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setContractSublocation(
          { res, toggle: toggle },
          TOGGLE_CONTRACT_SUBLOCATION,
          null,
          sublocation
        )
      )
    }
  }
