import { useQuery } from '@tanstack/react-query'
import restApi from 'apis/restApi'
import { useMemo } from 'react'
import { fetchToken } from 'utils/authUtils'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

export const useContractStatuses = (selectedId) => {
  const result = useQuery({
    queryKey: ['contractStatuses'],
    queryFn: async () =>
      await restApi.get('/contracts/status', {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      }),
  })

  const options = useMemo(
    () =>
      result.data?.data
        ? selectObject(
            showAvailable(result.data?.data),
            Number.parseInt(selectedId) || null,
            false,
            'All Statuses'
          )
        : [],
    [result.data?.data, selectedId]
  )

  return { ...result, options, data: result.data?.data }
}
