import { useQuery } from '@tanstack/react-query'
import restApi from 'apis/restApi'
import { useMemo } from 'react'
import { fetchToken } from 'utils/authUtils'
import { showAvailable, filterByLocation } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

export const useContractSublocations = (selectedParentId, selectedId) => {
  const result = useQuery({
    queryKey: ['contractSublocations'],
    queryFn: async () =>
      await restApi.get('/contracts/sublocation', {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      }),
  })

  const options = useMemo(
    () =>
      result.data?.data
        ? selectObject(
            filterByLocation(
              showAvailable(result.data?.data),
              Number.parseInt(selectedParentId) || null
            ),
            Number.parseInt(selectedId) || null,
            false,
            'All Sublocations'
          )
        : [],
    [result.data?.data, selectedId, selectedParentId]
  )

  return { ...result, options, data: result.data?.data }
}
