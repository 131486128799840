import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import { MDBRow, MDBCol, MDBInput } from 'mdbreact'
import React from 'react'
import { inputErrorClass } from 'utils/formUtils'

const AdminForm = ({
  params,
  setParams,
  contractTypes,
  contractLocations,
  selected,
  setSelected,
  errors,
}) => {
  //// HOOKS.

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <MDBRow>
      {/* name field */}
      {!(params?.name?.length >= 0) ? (
        ''
      ) : (
        <MDBCol md="12">
          <MDBInput
            autoFocus
            value={params.name}
            getValue={(value) => {
              setParams({ ...params, name: value })
              if (selected) setSelected({ ...selected, name: value })
            }}
            id="name-input"
            label="Name"
            type="text"
            outline
            required
            containerClass="required-label"
            className={`input-select${inputErrorClass(errors, !params.name)}`}
          />
        </MDBCol>
      )}

      {/* value field */}
      {!(params?.value?.length >= 0) ? (
        ''
      ) : (
        <MDBCol md="12">
          <MDBInput
            value={params.value}
            getValue={(value) => {
              setParams({ ...params, value: value })
              if (selected) setSelected({ ...selected, value: value })
            }}
            id="name-input"
            label="Value"
            type="text"
            outline
            required
            containerClass="required-label"
            className={`input-select${inputErrorClass(errors, !params.value)}`}
          />
        </MDBCol>
      )}

      {/* type select */}
      {!contractTypes ? (
        ''
      ) : (
        <MDBCol md="12">
          <Select
            id="select__contract-types"
            options={contractTypes}
            getTextContent={(v) => {
              if (v !== 'Choose A Type') {
                const typeSelection = contractTypes.filter(
                  (type) => type.value === v
                )
                setParams({
                  ...params,
                  contract_type_id: typeSelection[0].id,
                })

                const newType = contractTypes.filter((item) => item.value === v)
                if (selected)
                  setSelected({
                    ...selected,
                    contract_type: {
                      id: newType[0].id,
                      name: newType[0].value,
                    },
                  })
              }
            }}
            label="Type"
            defaultOption="Choose A Type"
            required
            search
            errors={errors}
          />
        </MDBCol>
      )}

      {/* location select */}
      {!contractLocations ? (
        ''
      ) : (
        <MDBCol md="12">
          <Select
            id="select__contract-locations"
            options={contractLocations}
            getTextContent={(v) => {
              if (v !== 'Choose A Location') {
                const locationSelection = contractLocations.find(
                  (location) => location.value === v
                )
                setParams({
                  ...params,
                  contract_location_id: locationSelection.id,
                })
                if (selected)
                  setSelected({
                    ...selected,
                    contract_location: {
                      id: locationSelection.id,
                      name: locationSelection.value,
                    },
                  })
              }
            }}
            label="Location"
            defaultOption="Choose A Location"
            required
            search
            errors={errors}
          />
        </MDBCol>
      )}
      <ErrorPanel results={errors} />
    </MDBRow>
  )
}

export default AdminForm
