import {
  fetchContractLocations,
  clearContractLocationOptions,
} from 'actions/contracts/contractLocationActions'
import {
  createContractSublocation,
  clearContractSublocationAction,
  clearContractSublocationErrors,
} from 'actions/contracts/contractSublocationActions'
import AdminForm from 'components/admin/AdminForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ContractSublocationCreate = ({
  formId,
  params,
  setParams,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { locationOptions } = useSelector((state) => state.contractLocations)
  const { lastAction, sublocationErrors } = useSelector(
    (state) => state.contractSublocations
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractLocations('form', null, true))
    return () => {
      dispatch(clearContractLocationOptions())
      dispatch(clearContractSublocationErrors())
      dispatch(clearContractSublocationAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        name: '',
        contract_location_id: 0,
      })
    }
  }, [params, setParams])

  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) || !locationOptions.length ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        const newParams = { name: params.name }
        if (params.contract_location_id > 0)
          newParams.contract_location_id = params.contract_location_id

        dispatch(createContractSublocation(newParams))
      }}
      className="form container"
      noValidate
    >
      <AdminForm
        params={params}
        setParams={setParams}
        contractLocations={locationOptions}
        errors={sublocationErrors}
      />
    </form>
  )
}

export default ContractSublocationCreate
