import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "emotion-theming";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./assets/scss/global.scss";

import { ProvideAuth } from "hooks/useAuth.js";
import App from "components/App";
import history from "./history";
import theme from "./theme";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()

ReactDOM.render(
  <ProvideAuth>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <App />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  </ProvideAuth>,
  document.querySelector('#root')
)
